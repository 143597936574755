// import { ReactComponent as CarIcon } from "./icons/experiments-car.svg";
// import { ReactComponent as BikeIcon } from "./icons/experiments-bike.svg";
// import { ReactComponent as StairsIcon } from "./icons/experiments-stairs.svg";

import { PiFileHtmlFill } from "react-icons/pi";
import { SiOpenai, SiDigitalocean } from "react-icons/si";

const dataExperiments = [
  {
    href: "https://careernewton.com/",
    title: "OpenAI Free Resume Builder",
    desc: "Solely built as a user-friendly resume builder that utilizes OpenAI's models to assist in creating a CV or resume that satisfies recruiter standards for content and layout, based on market leaders.",
    Icon: SiOpenai,
  },
  {
    href: "https://cyberin.in/",
    title: "Cyberin, Simplified Hosting",
    desc: "This project built on college days leveraging DigitalOcean’s cloud computing and cPanel. The goal was to offer users a hassle-free solution for obtaining a small hosting droplet to deploy their websites.",
    Icon: SiDigitalocean,
  },
  {
    href: "https://gopdf.pro/",
    title: "GoPdf, Smarter Documents, Smoother Workflow",
    desc: "GoPDF is a fun project that offers AI tools and APIs to boost productivity and simplify tasks. Its features include an AI Document Generator, Automation Workflows, PDF Conversion, an AI Blog Writer, Screenshot-to-Code, and the option to chat with your PDF documents.",
    Icon: PiFileHtmlFill,
  },
];

export default dataExperiments;
